<template>
  <v-app class="section-box" style="padding-top:60px">
    <v-card height="100%" class="d-flex">
      <iframe v-if="src" :src="src" frameborder="0" style="width:100%;height:100%"></iframe>
    </v-card>
  </v-app>
</template>

<script>

export default {
  name: 'DevelopExperienceDetail',
  data () {
      return {
        items: [
          { title: '新手教学', id:'xinhsoujiaoxu',src:'https://h5.ophyer.cn/metaverse/resource/metaverseHtml/xinhsoujiaoxu.html'},
          { title: '创作开始-制作第一个领地',id:'chuangzuokaishi', icon: 'mdi-account',src:'https://h5.ophyer.cn/metaverse/resource/metaverseHtml/chuangzuokaishi.html'},
          { title: '素材商店简介', id:'sucaijianjie',icon: 'mdi-account-group-outline',src:'https://h5.ophyer.cn/metaverse/resource/metaverseHtml/sucaijianjie.html'},
          { title: '如何创建VR全景',id:'chuangjianVR', icon: 'mdi-account-group-outline',src:'https://h5.ophyer.cn/metaverse/resource/metaverseHtml/chuangjianVR.html'},
          { title: 'VR编辑器功能介绍',id:'VRgongneng', icon: 'mdi-account-group-outline',src:'https://h5.ophyer.cn/metaverse/resource/metaverseHtml/VRgongneng.html'},
          { title: 'VR编辑器辅助功能介绍',id:'VRfuzhu', icon: 'mdi-account-group-outline',src:'https://h5.ophyer.cn/metaverse/resource/metaverseHtml/VRfuzhu.html'},
          { title: '3D编辑器界面说明', id:'3Djiemian',icon: 'mdi-account-group-outline',src:'https://h5.ophyer.cn/metaverse/resource/metaverseHtml/3Djiemian.html'},
          { title: '3D编辑器功能介绍', id:'3Dgongeng',icon: 'mdi-account-group-outline',src:'https://h5.ophyer.cn/metaverse/resource/metaverseHtml/3Dgongeng.html'},
          { title: '如何在全景中添加商品热点',id:'shangpinredian', icon: 'mdi-account-group-outline',src:'https://h5.ophyer.cn/metaverse/resource/metaverseHtml/shangpinredian.html'},
          { title: '如何使用沙盘功能',id:'shapangongneng', icon: 'mdi-account-group-outline',src:'https://h5.ophyer.cn/metaverse/resource/metaverseHtml/shapangongneng.html'},
          { title: '如何使用3D编辑器编写脚本',id:'3Djiaoben', icon: 'mdi-account-group-outline',src:'https://h5.ophyer.cn/metaverse/resource/metaverseHtml/3Djiaoben.html'},
        ],
        src:'',
      }
    },
  created() {
      this.items.map((item) => {
          if(this.$route.query.id === item.id){
              this.src = item.src;
          }
      })
  },
  methods: {
  }

}
</script>

<style lang="scss" scoped>
.item_style{
    cursor: pointer;
    position: relative;
}
.icon_style{
    position:absolute;
    top: 20px;
    right:-21px;
}

</style>